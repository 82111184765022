export const ADD_NAMES = 'ADD_NAMES';
export const CLEAR_ALL = 'CLEAR_ALL';
export const ADD_INPUT = 'ADD_INPUT';
export const CHANGE_TIMEPOINTS = 'CHANGE_TIMEPOINTS';
export const CHANGE_TIMEPOINTTASKS = 'CHANGE_TIMEPOINTTASKS';
export const CHANGE_EXTRATASKS = 'CHANGE_EXTRATASKS';
export const CHANGE_SPECIFIC_TASKS = 'CHANGE_SPECIFIC_TASKS';
export const CHANGE_SELECTED_MUNICIPALY = 'CHANGE_SELECTED_MUNICIPALY';
export const CHANGE_CHECKBOXES = 'CHANGE_CHECKBOXES';
export const CHANGE_WEIGHT = 'CHANGE_WEIGHT';
export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
export const CHANGE_LOGO_URL = 'CHANGE_LOGO_URL';

export const addAllData = (input) => ({
	type: ADD_NAMES,
	payload: input
});

export const clearAllData = (input) => ({
	type: CLEAR_ALL,
	payload: input
});

export const addInput = (key, input) => ({
	type: ADD_INPUT,
	payload: {
		key: key,
		data: input
	}
});

export const changeTimepoints = (timepoints) => ({
	type: CHANGE_TIMEPOINTS,
	payload: timepoints
})

export const changeTimepointTasks = (timepointTasks) => ({
	type: CHANGE_TIMEPOINTTASKS,
	payload: timepointTasks
})

export const changeExtraTasks = (extraTasks) => ({
	type: CHANGE_EXTRATASKS,
	payload: extraTasks
})

export const changeSpecificTasks = (tasks) => ({
	type: CHANGE_SPECIFIC_TASKS,
	payload: tasks
})

export const changeSelectedMunicipaly = (name) => ({
	type: CHANGE_SELECTED_MUNICIPALY,
	payload: name
})

export const changeCheckBoxes = (checkBoxes) => ({
	type: CHANGE_CHECKBOXES,
	payload: checkBoxes
})

export const changeWeights = (name, value) => ({
	type: CHANGE_WEIGHT,
	payload: {
		name: name,
		value: value,
	}
})

export const changeSettings = (key, settingsName, value) => ({
	type: CHANGE_SETTINGS,
	payload: {
		key: key,
		settingsName: settingsName,
		value: value,
	}
})

export const changeLogoUrl = (value) => ({
	type: CHANGE_LOGO_URL,
	payload: value
})