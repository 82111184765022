import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux'
import { changeTimepointTasks, changeExtraTasks, changeSpecificTasks } from '../../actions/backendDataAction'
import { ReactComponent as Trashcan } from '../resources/img/trashcan.svg';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};



class EditInputField extends React.Component {

    componentWillMount() {
        this.setState({
            taskData: this.props.values,
            originalName: this.props.values.name
        })
    }

    changeTask(newData) {
        this.props.changeTask(newData);
    }

    onChangeStateValue(event) {
        this.setState({
            taskData: {
                ...this.state.taskData,
                [event.target.id]: event.target.value
            }
        }, () => this.state.taskData)
    }

    onChangeStateChecked(event) {
        this.setState({
            taskData: {
                ...this.state.taskData,
                [event.target.id]: event.target.checked
            }
        }, () => this.state.taskData)
    }

    onChangeSelectionName(event) {
        let selectionsList = this.state.taskData.selections;
        const index = selectionsList.findIndex((e) => e === event.target.id);
        selectionsList[index] = event.target.value;

        const { [event.target.id]: _, ...oldStandardTimes } = this.state.taskData.standardTimes;
        const newStandardTimes = { ...oldStandardTimes, [event.target.value]: this.state.taskData.standardTimes[event.target.id] }

        const { [event.target.id]: __, ...oldSelectionDescriptions } = this.state.taskData.selectionDescriptions;
        const newSelectionDescriptions = { ...oldSelectionDescriptions, [event.target.value]: this.state.taskData.selectionDescriptions[event.target.id] }

        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: selectionsList,
                standardTimes: newStandardTimes,
                selectionDescriptions: newSelectionDescriptions,
            }
        }, () => this.state.taskData)
    }

    onChangeStandardTime(event) {
        const input = parseInt(event.target.value);
        let res;
        if (event.target.value === "") {
            res = event.target.value;
        } else if (!Number.isNaN(input)) {
            res = input;
        }

        this.setState({
            taskData: {
                ...this.state.taskData,
                standardTimes: {
                    ...this.state.taskData.standardTimes,
                    [event.target.id]: res
                }
            }
        })

    }

    onChangeSelectionDescriptions(event) {
        this.setState({
            taskData: {
                ...this.state.taskData,
                selectionDescriptions: {
                    ...this.state.taskData.selectionDescriptions,
                    [event.target.id]: event.target.value
                }
            }
        })
    }

    saveChanges(oldName, timepoint) {
        this.props.changeTimePoint(oldName, timepoint)
        this.props.closeEdit();
    }

    removeTimepoint(timepoint) {
        this.props.removeTimepoint(timepoint);
        this.props.closeEdit();
    }

    addLevel() {
        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: this.state.taskData.selections.concat("Ny nivå"),
                standardTimes: { ...this.state.taskData.standardTimes, ["Ny nivå"]: 0 },
                selectionDescriptions: { ...this.state.taskData.selectionDescriptions, ["Ny nivå"]: "Beskrivning" }
            }
        })
    }

    setSpecificTask(event) {
        const { selectedMunicipaly, data } = this.props.backendDataReducer;
        console.log(data)
        const { specificExtraTasks } = data[selectedMunicipaly];
        const key = event.target.id;
        let result = specificExtraTasks;
        let i = specificExtraTasks.indexOf(key)
        if (i < 0) {
            result.push(key)
        } else {
            result.splice(i, 1)
        }
        this.props.changeSpecificTasks(result);
    }

    removeLevel(i) {
        const { selections, selectionDescriptions, standardTimes } = this.state.taskData;
        const taskName = selections[i];
        const newSelections = selections;
        const newSelectionDescriptions = selectionDescriptions;
        const newStandardTimes = standardTimes;
        newSelections.splice(i, 1);
        delete newSelectionDescriptions[taskName];
        delete newStandardTimes[taskName];
        this.setState({
            taskData: {
                ...this.state.taskData,
                selections: newSelections,
                selectionDescriptions: newSelectionDescriptions,
                standardTimes: newStandardTimes,
            }
        })
    }

    changeFrequency(event) {
        this.setState({
            taskData: {
                ...this.state.taskData,
                hasFrequency: event.target.checked
            }
        })
    }

    render() {
        const { taskData } = this.state;
        const { extraTasks } = this.props;

        return (
            <Grid container spacing={3} style={{ borderBottomRadius: 1, borderBottomWidth: 1, borderBottomColor: 'black' }}>
                {taskData &&

                    <React.Fragment>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={7} style={{ display: 'flex', flexDirection: 'column' }}>

                            <TextField id="name" label="Namn" type="text" variant="outlined" value={taskData.name} style={{ marginTop: 16 }} onChange={(event) => this.onChangeStateValue(event)} />
                            <TextField id="description" label="Livsområde" type="text" variant="outlined" value={taskData.description} style={{ marginTop: 16 }} onChange={(event) => this.onChangeStateValue(event)} />
                            <FormControlLabel
                                style={{ marginTop: 10 }}
                                control={<Checkbox checked={taskData.double} id="double" onChange={(event) => this.onChangeStateChecked(event)} />}
                                label="Dubbelbemanning"
                            />
                            <FormControlLabel
                                style={{ marginTop: 10 }}
                                control={<Checkbox checked={taskData.shared} id="shared" onChange={(event) => this.onChangeStateChecked(event)} />}
                                label="Kan delas med make/maka"
                            />

                            {extraTasks && (
                                <>
                                    <React.Fragment>
                                        <FormControlLabel
                                            style={{ marginTop: 10 }}
                                            control={<Checkbox checked={taskData.hasFrequency} id="shared" onChange={(event) => this.changeFrequency(event)} />}
                                            label="Visa frekvensval"
                                        />
                                    </React.Fragment>
                                    <Typography variant="h6" gutterBottom style={{ paddingTop: 10 }}>
                                        Välj vilka frekvenser
                                    </Typography>
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.sevenTimesAWeek} id="sevenTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="7 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.sixTimesAWeek} id="sixTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="6 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.fiveTimesAWeek} id="fiveTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="5 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.fourTimesAWeek} id="fourTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="4 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.threeTimesAWeek} id="threeTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="3 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.twoTimesAWeek} id="twoTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="2 gånger i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.oneTimesAWeek} id="oneTimesAWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="1 gång i veckan"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.secondWeek} id="secondWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="Varannan vecka"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.thirdWeek} id="thirdWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="Var tredje vecka"
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={<Checkbox checked={taskData.fourthWeek} id="fourthWeek" onChange={(event) => this.onChangeStateChecked(event)} />}
                                        label="Var fjärde vecka"
                                    />
                                </>
                            )
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
                                <Button style={{ marginLeft: 50 }} variant="outlined" color="primary" onClick={() => this.saveChanges(this.state.originalName, this.state.taskData)}>Lägg till ändringar</Button>
                                <Button style={{ marginLeft: 50, marginTop: 10 }} variant="outlined" color="secondary" onClick={() => this.removeTimepoint(this.state.taskData)}>Ta bort</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={9} style={{ borderBottomWidth: 1, borderBottomColor: '#cad8ff', borderBottomStyle: 'solid', marginBottom: 20 }}>
                            {
                                taskData.selections.map((level, i) => {
                                    return (
                                        <div style={{ marginBottom: 10 }}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>{level}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails style={{ justifyContent: 'space-evenly' }}>
                                                    <TextField id={level} label="Namn" value={level} variant="outlined" onChange={(event) => this.onChangeSelectionName(event)} />
                                                    <TextField id={level} label="Standardtid (minuter)" value={taskData.standardTimes[level]} variant="outlined" onChange={(event) => this.onChangeStandardTime(event)} />
                                                    <TextField
                                                        id={level}
                                                        label="Beskrivning av nivå"
                                                        multiline
                                                        rowsMax="4"
                                                        value={taskData.selectionDescriptions[level]}
                                                        onChange={(event) => this.onChangeSelectionDescriptions(event)}
                                                        variant="outlined"
                                                    />
                                                    {i !== 0 && <Trashcan style={{ cursor: 'pointer', marginRight: -20 }} onClick={() => this.removeLevel(i)} />}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    )
                                })
                            }
                            <Button style={{ cursor: 'pointer', marginRight: 10, marginBottom: 20, marignTop: 20 }} variant="outlined" color="primary" onClick={() => this.addLevel()}> Lägg till nivå</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} />
                    </React.Fragment>
                }
            </Grid>
        );
    }
}


const mapStateToProps = ({ backendDataReducer }) => ({
    backendDataReducer
});

const mapDispatchToProps = (dispatch) => ({
    changeTimepointTasks: (oldName, timepointTasks) => dispatch(changeTimepointTasks(oldName, timepointTasks)),
    changeExtraTasks: (oldName, timepointTasks) => dispatch(changeExtraTasks(oldName, timepointTasks)),
    changeSpecificTasks: (task) => dispatch(changeSpecificTasks(task)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInputField)