const initialState = {
  municipalies: [],
  selectedMunicipaly: "",
  data: {},
  settings: {},
  logoUrl: {}
}

const backendDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NAMES':
      let names = []
      let inputs = {}
      let settings = {}
      let logoUrl = {}
      action.payload.map((item) => {
        names.push(item["name"]);
        inputs = { ...inputs, [item["name"]]: item["input"] }
        settings = {
          ...settings, [item["name"]]: {
            formSettings: item["input"]["formSettings"],
            calculationOutputSettings: item["input"]["calculationOutputSettings"],

          }
        }
        logoUrl = { ...logoUrl, [item["name"]]: item["logoUrl"] }
      })

      return {
        ...state,
        municipalies: names,
        data: inputs,
        settings: settings,
        logoUrl: logoUrl,
      }

    case 'CLEAR_ALL':
      return {
        ...initialState
      }

    case 'ADD_INPUT':
      const data = {
        [action.payload.key]: {
          timepoints: action.payload.data.input.timepoints,
          timepointTasks: action.payload.data.input.timepointTasks,
          extraTasks: action.payload.data.input.extraTasks,
          checkboxes: action.payload.data.input.checkboxes,
        }
      }

      const newSettings2 = {
        [action.payload.key]: {
          formSettings: action.payload.data.input.formSettings,
          calculationOutputSettings: action.payload.data.input.calculationOutputSettings,
        }
      }

      const logoUrl2 = {
        [action.payload.key]: action.payload.data.logoUrl
      }

      return {
        ...state,
        selectedMunicipaly: action.payload.key,
        data: data,
        settings: newSettings2,
        logoUrl: logoUrl2
      }


    case 'CHANGE_TIMEPOINTS':
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            timepoints: action.payload,
          }
        }
      }


    case 'CHANGE_TIMEPOINTTASKS':
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            timepointTasks: action.payload,
          }
        }
      }

    case 'CHANGE_EXTRATASKS':
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            extraTasks: action.payload,
          }
        }
      }

    case 'CHANGE_SPECIFIC_TASKS':
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            specificExtraTasks: action.payload,
          }
        }
      }


    case 'CHANGE_SELECTED_MUNICIPALY':
      return {
        ...state,
        selectedMunicipaly: action.payload,
      }

    case 'CHANGE_CHECKBOXES':
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            checkboxes: action.payload,
          }
        }
      }

    case 'CHANGE_WEIGHT':
      const oldWeights = state.data[state.selectedMunicipaly].weights;
      const newWeights = { ...oldWeights, [action.payload.name]: action.payload.value }
      return {
        ...state,
        data: {
          ...state.data,
          [state.selectedMunicipaly]: {
            ...state.data[state.selectedMunicipaly],
            weights: newWeights,
          }
        }
      }

    case 'CHANGE_SETTINGS':
      const oldSettings = state.settings[state.selectedMunicipaly];
      const newSettings = {
        ...oldSettings,
        [action.payload.key]: {
          ...oldSettings[action.payload.key],
          [action.payload.settingsName]: action.payload.value
        }
      }
      return {
        ...state,
        settings: {
          ...state.state,
          [state.selectedMunicipaly]: newSettings,
        }
      }

    case 'CHANGE_LOGO_URL':

      return {
        ...state,
        logoUrl: {
          ...state.logoUrl,
          [state.selectedMunicipaly]: action.payload
        }
      }

    default:
      return {
        ...state
      }
  }
}

export default backendDataReducer