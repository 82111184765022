import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from "axios";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NavBar from '../../partials/NavButtons/NavBar';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    extras: {
        padding: 20        
    }
});
  
const BASE_URL = process.env.REACT_APP_BASE_URL;

const UserManagment = (props) => {
  const [users, setUsers] = React.useState([])
  const accessToken = props.token;

  const getUsers = async () => {
    const users = await axios({
      method: "GET",
      url: `${BASE_URL}/users/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Eid ${accessToken}`,
      },
    })
    setUsers(users.data)
  }

  const disableUser = async (userId) => {
    
    await axios({
      method: "post",
      url: `${BASE_URL}/users/disable`,
      data:{
        id: userId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Eid ${accessToken}`,
      },
    })

    getUsers()
    toast("Användare avaktiverad")
  }
  
  const enableUser = async (userId) => {
    
    await axios({
      method: "post",
      url: `${BASE_URL}/users/enable`,
      data:{
        id: userId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Eid ${accessToken}`,
      },
    })

    getUsers()
    toast("Användare aktiverad")
  }

  React.useEffect(() => {
    getUsers()
  }, [])

  const classes = useStyles();

  return (
    <div style={{width:"1000px"}}>
      <ToastContainer />
      <NavBar isAdmin={props.isAdmin} />
      <div className={classes.extras}>
      <Link to="/users/new"><Button variant='outlined'>Lägg till ny användare</Button></Link>
      </div>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Namn</TableCell>
            <TableCell>Typ av användare</TableCell>
            <TableCell>Aktiverad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell>{row.role === "admin" ? "Administratör" : "Användare"}</TableCell>
              <TableCell>
                <Button onClick={() => row.disabled ? enableUser(row.id) : disableUser(row.id)} variant="outlined">{row.disabled ? "Aktivera" : "Avaktivera"}</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <div className={classes.extras}>
      <Link to="/users/new"><Button variant='outlined'>Lägg till ny användare</Button></Link>
      </div>
    </TableContainer>
    </div>
  )
}


export default UserManagment