const initialState = {
  weeksOff: "",
  shared: false,
  timepointTasks: {},
  extraTasks: {},
  checkboxes: {}
}


const answerModelReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'UPDATE_WEEKS_OFF':
      return {
        ...state,
        weeksOff: action.payload,
      }

    case 'UPDATE_SHARED':
      return {
        ...state,
        shared: action.payload,
      }

    case 'SET_ALL_TIMEPOINTTASKS':
      return {
        ...state,
        timepointTasks: action.payload,
      }

    case 'SET_TIMEPOINTTASKS':
      const result = { ...state.timepointTasks, [action.payload.time]: action.payload.timePointTasks }
      return {
        ...state,
        timepointTasks: result,
      }

    case 'UPDATE_TIMEPOINTTASK':
      const { timepointTasks } = state;
      // Change the timepointTask for the specified timepoint
      const newTask = { ...timepointTasks[action.payload.time], [action.payload.taskName]: action.payload.taskData }
      // Join the newTask with the other timepointtasks for other timepoints
      const newTasks = { ...timepointTasks, [action.payload.time]: newTask }
      return {
        ...state,
        timepointTasks: newTasks
      }

    case 'SET_EXTRATASKS':
      return {
        ...state,
        extraTasks: action.payload,
      }

    case 'UPDATE_EXTRATASK':
      const { extraTasks } = state;
      const newExtraTasks = { ...extraTasks, [action.payload.taskName]: action.payload.taskData }
      return {
        ...state,
        extraTasks: newExtraTasks,
      }

    case 'SET_CHECKBOXES':

      return {
        ...state,
        checkboxes: action.payload,
      }

    case 'UPDATE_CHECKBOXES':
      const { checkboxes } = state;
      const newCheckboxes = { ...checkboxes, [action.payload.key]: action.payload.checked }
      return {
        ...state,
        checkboxes: newCheckboxes,
      }
    default:
      return {
        ...state
      }
  }
}

export default answerModelReducer