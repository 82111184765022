import React from 'react';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputFieldClient from './InputFieldClient';
import InputFields from './InputFields';
import EditInputField from './EditInputField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { changeTimepointTasks, changeExtraTasks } from '../../actions/backendDataAction'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


class FormPage extends React.Component {

  componentWillMount() {
    this.setState({
      editIndex: -1,
      timePointTasks: this.props.values,
      addButtonShowing: true,
    })
  }


  editField(index) {
    this.setState({
      editIndex: index,
      addButtonShowing: false,
    })
  }

  closeEdit() {
    this.setState({
      editIndex: -1
    })
    this.showAddButton()
  }

  addTimepoint() {
    const data = {
      "name": "",
      "description": "",
      "shared": false,
      "selections": ["Nivå 1"],
      "standardTimes": {
        "Nivå 1": 0,
      },
      "selectionDescriptions": {
        "Nivå 1": "Beskrivning",
      },
      hasFrequency: true
    }

    const newTasks = this.props.values.concat(data);
    const edit = this.props.values.length;
    this.setState({
      editIndex: edit,
      addButtonShowing: false,
    })
    if (this.props.ordinary) {
      this.props.changeTimepointTasks(newTasks);
    } else {
      this.props.changeExtraTasks(newTasks);
    }
  }

  showAddButton() {
    this.setState({
      addButtonShowing: true,
    })
  }


  changeTimePoint(oldName, timepoint) {
    const oldTasks = this.props.values;
    const indexToChange = oldTasks.findIndex((e) => e.name === oldName);
    let newTasks = oldTasks;
    if (indexToChange === -1) {
      newTasks = newTasks.concat(timepoint);
    } else {
      newTasks[indexToChange] = timepoint;
    }
    if (this.props.ordinary) {
      this.props.changeTimepointTasks(newTasks);
    } else {
      this.props.changeExtraTasks(newTasks);
    }
  }

  removeTimepoint(timepoint) {
    const newTasks = this.props.values.filter((item) => item.name !== timepoint.name);
    if (this.props.ordinary) {
      this.props.changeTimepointTasks(newTasks);
    } else {
      this.props.changeExtraTasks(newTasks);
    }

  }

  getAnswerModel(name) {
    const { title, extraTasks } = this.props;
    const result = extraTasks ? this.props.answerModelReducer.extraTasks[name] : this.props.answerModelReducer.timepointTasks[title][name];
    if (result) {
      return result
    } else {
      return { frequency: 0, selection: "" }
    }
  }

  render() {
    const { title, dontShowLevels, canEdit, values, client, extraTasks } = this.props;

    return (
      <React.Fragment>
        {values &&
          <React.Fragment>
            <div className={"form-time-container" + (dontShowLevels ? " dont-show-labels" : '')}>
              <Typography variant="h6" gutterBottom style={{ paddingTop: 10 }}>
                {title}
              </Typography>
              {values.map((key, index) => {
                if (index === this.state.editIndex) {
                  return <EditInputField
                    ordinary={this.props.ordinary}
                    dontShowLevels={dontShowLevels}
                    title={key.name}
                    values={key} index={index}
                    levels={key.selectionDescriptions}
                    closeEdit={() => this.closeEdit()}
                    changeTimePoint={(oldName, timepoint) => this.changeTimePoint(oldName, timepoint)}
                    removeTimepoint={(timepoint) => this.removeTimepoint(timepoint)}
                    extraTasks={extraTasks}
                  />
                } else {
                  if (client) {
                    return <InputFieldClient
                      dontShowLevels={dontShowLevels}
                      title={key.name}
                      values={key}
                      index={index}
                      levels={key.selectionDescriptions}
                      canEdit={canEdit && this.state.editIndex === -1}
                      editField={(index) => this.editField(index)}
                      timepoint={title}
                      answerModel={this.getAnswerModel(key.name)}
                      extraTasks={extraTasks}
                    />
                  } else {
                    return <InputFields
                      dontShowLevels={dontShowLevels}
                      title={key.name}
                      values={key}
                      index={index}
                      levels={key.selectionDescriptions}
                      canEdit={canEdit && this.state.editIndex === -1}
                      editField={(index) => this.editField(index)}
                      extraTasks={extraTasks}
                    />
                  }
                }
              })
              }
              {this.state.addButtonShowing && canEdit &&
                <Button style={{ cursor: 'pointer', marginRight: 10, marginBottom: 20, marignTop: 20 }} variant="outlined" color="primary" onClick={() => this.addTimepoint()}> Lägg till </Button>
              }
            </div>
          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ answerModelReducer }) => ({
  answerModelReducer
});

const mapDispatchToProps = (dispatch) => ({
  changeTimepointTasks: (oldName, timepointTasks) => dispatch(changeTimepointTasks(oldName, timepointTasks)),
  changeExtraTasks: (oldName, timepointTasks) => dispatch(changeExtraTasks(oldName, timepointTasks)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPage)
