import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router-dom'
import axios from 'axios';
import { Button, Input, Typography } from '@material-ui/core';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const useEid = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const testAuthentication = async (token) => {
        return axios({
            method: 'GET',
            url: `${BASE_URL}/test/auth`,
            headers: {
                Authorization: `Eid ${token}`
            }
        })
    }

    useEffect(() => {
        const url = new URL(window.location.href);


        if (url.searchParams.get('grandIdSession')) {
            const token = url.searchParams.get('grandIdSession')

            testAuthentication(token).then((x) => {
                sessionStorage.setItem("eidtoken", token)
                setIsAuthenticated(true)
                setIsAdmin(x.data.role === "admin")
                setLoading(false)
            }).catch(() => {
                sessionStorage.removeItem("eidtoken")
                setLoading(false)
                setIsAuthenticated(false)
            }).finally(() => {
                const queryParams = new URLSearchParams(location.search)
                queryParams.delete('grandIdSession')
                navigate('/', {
                    replace: true,
                })
            })

            return
        } else if (sessionStorage.getItem("eidtoken")) {
            const token = sessionStorage.getItem("eidtoken")
            testAuthentication(token).then((x) => {
                setIsAuthenticated(true)
                setIsAdmin(x.data.role === "admin")
                setLoading(false)
                sessionStorage.setItem("eidtoken", token)
            }).catch(() => {
                setIsAuthenticated(false)
                setLoading(false)
                sessionStorage.removeItem("eidtoken")
            })
        } else {
            setIsAuthenticated(false)
            setLoading(false)
        }
    }, [])


    const loginWithRedirect = (personalNumber) => {
        console.log("wow")
        if (personalNumber) {
            window.location.href = `${BASE_URL}/auth/eidentitet/init?id=${personalNumber}`
        } else {
            window.location.href = `${BASE_URL}/auth/eidentitet/init`
        }

    }

    return {
        isAuthenticated,
        loading,
        loginWithRedirect,
        isAdmin
    }
}


export const SignInView = ({setPersonNumber, personalNumber, loginWithRedirect}) => {
    return (
        <>
          <div className='login'>
            <Typography variant="h3">Beslutsstöd - Inrikta</Typography>
            <Typography variant="p">Logga in med bankid genom att fylla i ditt personnummer nedan.</Typography>
            <div class="login-section">
              <Input placeholder='19780304xxxx' onChange={(e) => setPersonNumber(e.target.value)} value={personalNumber} />
            </div>
            <div class="login-section">
              <Button variant='outlined' onClick={() => loginWithRedirect(personalNumber)}>Logga in</Button>
            </div>
          </div>
        </>
      )
}