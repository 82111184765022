import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import * as MdIcons from 'react-icons/md';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;



const StyledButton = styled.div`
  color: white;
  align-text: center;
  z-index: 2;
  &:hover{
    cursor: pointer;
  }
`;

const logOut = () => {
  console.log("out")
  const accessToken = sessionStorage.getItem("eidtoken")
  const config = {
    method: "GET",
    url: `${BASE_URL}/auth/eidentitet/logout`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Eid ${accessToken}`
    }
  };

  axios(config)
    .then((res) => {
      window.location.reload()

    })
    .catch((e) => {
    });
}

const LogoutButton = () => {
  return (
    <Tooltip title="Logga ut">
      <StyledButton > <MdIcons.MdOutlineLogout size={40} onClick={logOut} /> </StyledButton>
    </Tooltip>
  )
}

export default LogoutButton