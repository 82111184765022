import React from 'react'
import { BoxLoading } from 'react-loadingg';

const Loading = () => {
    return (
        <div>
            <BoxLoading size='large' />
        </div>
    )
}

export default Loading
