import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux'
import { changeTimepointTasks, changeExtraTasks, changeCheckBoxes } from '../../actions/backendDataAction'



class EditCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.editContent = this.editContent.bind(this);
  }

  componentWillMount() {
    this.setState({
      edit: false,
      checkboxes: this.props.checkboxes,
    })
  }

  changeEditMode() {
    this.setState({
      edit: !this.state.edit,
    })
  }

  editContent(oldIndex, event) {
    const oldDescription = this.state.checkboxes[oldIndex][event.target.id]
    let oldCheckboxes = this.state.checkboxes;
    oldCheckboxes[oldIndex] = { [event.target.value]: oldDescription }
    this.setState({
      checkboxes: oldCheckboxes,
    })
  }

  editDescription(oldIndex, event) {
    let oldCheckboxes = this.state.checkboxes;
    oldCheckboxes[oldIndex] = { [event.target.id]: event.target.value }
    this.setState({
      checkboxes: oldCheckboxes
    })
  }

  updateCheckBoxes() {
    let array = {}
    this.state.checkboxes.map((option, index) => {
      const key = Object.keys(option)[0];
      const description = option[key];
      array[key] = description;
    })
    this.props.changeCheckBoxes(array)
    this.props.changeEditMode();
  }

  render() {
    const { checkboxes } = this.state;
    return (
      <React.Fragment>
        <FormControl>
          {checkboxes.map((checkbox, index) => {
            const key = Object.keys(checkbox)[0];
            const value = checkbox[key];
            return (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                <TextField
                  variant="outlined"
                  style={{ marginRight: 20 }}
                  id={key}
                  label={"Titel"}
                  value={key}
                  onChange={(event) => this.editContent(index, event)}
                />
                <TextField
                  variant="outlined"
                  multiline
                  rowsMax="4"
                  id={key}
                  label={"Beskrivning"}
                  value={value}
                  onChange={(event) => this.editDescription(index, event)}
                />
              </div>)
          })}
          <div style={{ marginTop: 20 }}>
            <Button style={{ cursor: 'pointer', marginRight: 10 }} variant="outlined" color="primary" onClick={() => this.updateCheckBoxes()}> Klar </Button>
          </div>
        </FormControl>
      </React.Fragment>
    );
  }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  changeTimepointTasks: (oldName, timepointTasks) => dispatch(changeTimepointTasks(oldName, timepointTasks)),
  changeExtraTasks: (oldName, timepointTasks) => dispatch(changeExtraTasks(oldName, timepointTasks)),
  changeCheckBoxes: (checkboxes) => dispatch(changeCheckBoxes(checkboxes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCheckbox)
