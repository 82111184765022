import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { changeLogoUrl } from '../../actions/backendDataAction'



class EditLogoUrl extends React.Component {

    state = {
        url: ""
    }
    componentWillMount() {
        this.setState({
            url: this.props.logoUrl
        })
    }

    updateUrl(event) {
        this.setState({
            url: event.target.value
        })
    }

    saveChanges() {
        this.props.changeLogoUrl(this.state.url)
        this.props.closeEdit();
    }

    render() {
        return (
            <div style={{ display: 'flex', marginTop: 20 }}>
                <TextField id="Logo ur" label="Logo ur" type="text" variant="outlined" value={this.state.url} style={{ width: '100%' }} onChange={(event) => this.updateUrl(event)} />
                <Button style={{ cursor: 'pointer', backgroundColor: 'white', marginLeft: 10 }} variant="outlined" color="primary" onClick={() => this.saveChanges()}> Klar </Button>
            </div>
        );
    }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    changeLogoUrl: (logo) => dispatch(changeLogoUrl(logo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLogoUrl)