import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';



class EditTime extends React.Component {

  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render() {

    return (
      <React.Fragment>
        <div style={{
          height: 130,
          width: 300,
          marginTop: 20,
          marginBottom: 40,
          boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          padding: 20,
          paddingTop: 0,
        }}>

          <form style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '100%'
          }}
          >
            <div style={{
              height: 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 20,
              cursor: 'pointer',
            }} onClick={() => this.props.close()}>
              X
            </div>
            <TextField id="time" placeholder="Tillfälle" type="text" inputProps={200} onChange={(e) => this.handleChange(e)} />
            <Button disabled={this.state.value === ""} variant="outlined" color="primary" onClick={() => this.props.save(this.state.value)}>
              Spara
            </Button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default EditTime


