import React from "react";
import styled from "styled-components";
import LogoutButton from "./LogoutButton";
import SearchButton from "./SearchButton";
import CalculateButton from "./CalculateButton";
import UsersButton from "./UsersButton";

const Nav = styled.div``;


const NavBar = (props) => {
  return (
    <>
      <Nav className="navbar">
        {
          props.logo ? (<img src={props.logo} alt="logo" className="App__logo" />) : null
        }
        <div className="buttons-header">
          {props.isAdmin && <UsersButton />}
          <CalculateButton />
          <SearchButton />
          <LogoutButton />
        </div>
      </Nav>
    </>
  );
};

export default NavBar;
