import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormPage from "../partials/FormPage";
import EditTime from "../partials/EditTime";
import CheckboxPage from "../partials/CheckboxPage";
import EditLogoUrl from "../partials/EditLogoUrl";
import axios from "axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as Question } from "../resources/img/question.svg";
import { connect } from "react-redux";
import {
  addAllData,
  addInput,
  changeTimepoints,
  changeSelectedMunicipaly,
  changeWeights,
  changeSettings,
  changeLogoUrl,
  clearAllData,
} from "../../actions/backendDataAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from "file-saver";

const path = require("path");
require("dotenv").config({ path: path.resolve(__dirname, ".env") });


// Set the correct URL when calling APIs
const BASE_URL = process.env.REACT_APP_BASE_URL;


class Admin extends React.Component {
  state = {
    answerModel: {},
    activeSteps: [],
    timePerTimepoint: {},
    editTime: false,
    selectKey: "",
    showBackdrop: false,
    newMunicipalityName: "",
    editUrl: false
  };

  componentDidMount() {
    this.getAllData();
  }

  async getAllData() {
    const accessToken = this.props.token
    
    const config = {
      method: "get",
      url: `${BASE_URL}/municipalities`,
      headers: {
        Authorization: `Eid ${accessToken}`,
      },
    };
    axios(config).then(
      (res) => {
        this.props.addAllData(res.data);
      },
      (error) => {
        console.log("error", error);
      }
    ); 
  }

  // new data = new timepoints
  changeTimepoints(newTimepoints) {
    this.props.changeTimepoints(newTimepoints);
  }

  getHours = (num) => {
    return Math.floor(num);
  };

  getMinutes = (num) => {
    return Math.floor((num - this.getHours(num)) * 60);
  };

  createTimeString = (hours) => {
    return "" + this.getHours(hours) + "h " + this.getMinutes(hours) + "min";
  };

  timeStepCheckboxClicked = (key) => {
    let i = this.state.activeSteps.indexOf(key);
    if (i < 0) {
      this.state.activeSteps.push(key);
    } else {
      this.state.activeSteps.splice(i, 1);
    }

    this.setState({
      update: !this.state.update,
    });
  };

  renderCheckBoxes(data) {
    return data.map((key) => {
      return (
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={this.state.activeSteps.includes(key)}
              color="primary"
              onChange={() => {
                this.timeStepCheckboxClicked(key);
              }}
            />
          }
          label={key}
          labelPlacement="end"
        />
      );
    });
  }


  showEditTime() {
    this.setState({
      editTime: !this.state.editTime,
    });
  }

  removeSelected() {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    const newTimepoints = this.props.backendDataReducer.data[
      selectedMunicipaly
    ].timepoints.filter((item) => !this.state.activeSteps.includes(item));
    this.setState({
      activeSteps: [],
    });
    this.changeTimepoints(newTimepoints);
  }

  saveTimepoint(text) {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    const newTimepoints =
      this.props.backendDataReducer.data[selectedMunicipaly].timepoints.concat(
        text
      );
    this.changeTimepoints(newTimepoints);
    this.showEditTime();
  }

  selectMunicipaly(event) {
    this.setState({
      selectKey: event.target.value,
    });
    this.props.changeSelectedMunicipaly(event.target.value);
  }

  async saveToBackend() {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    const data = this.props.backendDataReducer.data[selectedMunicipaly];
    const settings = this.props.backendDataReducer.settings[selectedMunicipaly];
    const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly];
    const dataOut = {
      name: selectedMunicipaly,
      logoUrl: logoUrl,
      input: data,
      formSettings: settings.formSettings,
      calculationOutputSettings: settings.calculationOutputSettings,
    };
    const accessToken = this.props.token
    

    const config = {
      method: "post",
      url: `${BASE_URL}/update`,
      headers: {
        Authorization: `Eid ${accessToken}`,
      },
      data: dataOut,
    };

    console.log(data)
    axios(config)
      .then((res) => {
        //console.log("post result", res)
        toast("Sparat och klart")
      })
      .catch((e) => {
        toast("Det gick inte att spara")
      }); 
  }

  changeWeight(name, event) {
    const input = event.target.value;
    const re_number = /^[+-]?(\d*\.)?\d+$/;
    const weight = Number(input);

    if (input === "" || re_number.test(input)) {
      this.props.changeWeights(name, weight);
    } else {
      this.props.changeWeights(name, input);
    }

    //    const input = event.target.value;
    //    const weight = Number(input);
    //    console.log(parseFloat(weight))
    // if(!Number.isNaN(weight)){
    // 		this.props.changeWeights(name, event.target.value);
    // 	}
  }

  async addNewMunicipality() {
    const accessToken = this.props.token
    if (accessToken) {
      console.log("Authorized");
    } else {
      console.log("Not Authorized");
    }

    const result = {
      name: this.state.newMunicipalityName,
      logoUrl: "",
      input: {
        timepoints: [],
        timepointTasks: [],
        extraTasks: [],
        specificExtraTasks: [],
        checkboxes: [],
        weights: {
          alpha: 2,
          beta: 2,
        },
        formSettings: {
          showSettings: false,
          showDescription: false,
        },
        calculationOutputSettings: {
          showTimepoints: false,
        },
      },
    };
    console.log(`result`, result);

    const config = {
      method: "post",
      url: `${BASE_URL}/upload`,
      headers: {
        Authorization: `Eid ${accessToken}`,
      },
      data: result,
    };

    axios(config)
      .then((res) => {
        toast("Sparat och klart")

        this.getAllData();
      })
      .catch((e) => {
        toast("Det gick inte att spara")

      });
    this.setState({
      newMunicipalityName: "",
      showBackdrop: false,
    }); 
  }

  changeSettings(key, settingName, value) {
    this.props.changeSettings(key, settingName, value);
  }

  changeLogoUrl(event) {
    this.props.changeLogoUrl(event.target.value);
  }

  editUrl() {
    this.setState({
      editUrl: !this.state.editUrl,
    });
  }

  saveStateToFile() {
    var file = new Blob([JSON.stringify(this.props.backendDataReducer)], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(file, "state.json");
  }

  render() {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;

    const data = this.props.backendDataReducer.data[selectedMunicipaly];
    const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly];
    // If there is a problem with settings. Check how settings is stored in the DB and then check backendDataReducer
    const settings = this.props.backendDataReducer.settings[selectedMunicipaly];

    let timepoints;
    let timepointTasks;
    let extraTasks;
    let checkboxes;
    if (data) {
      timepoints = data.timepoints;
      timepointTasks = data.timepointTasks;
      extraTasks = data.extraTasks;
      checkboxes = data.checkboxes;
    }
    const { municipalies } = this.props.backendDataReducer;
    return (
      <React.Fragment>
        <ToastContainer />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.showBackdrop}
          onClose={() => this.setState({ showBackdrop: false })}
        >
          <Paper
            style={{
              marginTop: 200,
              marginLeft: "auto",
              marginRight: "auto",
              width: 400,
              height: 300,
              zIndex: 2000,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingLeft: 40,
              paddingRight: 40,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              style={{ paddingTop: 20 }}
            >
              Lägg till ny kommun
            </Typography>
            <TextField
              id={"hej"}
              label="Namn"
              value={this.state.newMunicipalityName}
              variant="outlined"
              onChange={(event) =>
                this.setState({ newMunicipalityName: event.target.value })
              }
            />
            <Button
              style={{ cursor: "pointer", backgroundColor: "white" }}
              variant="outlined"
              color="primary"
              onClick={() => this.addNewMunicipality()}
            >
              {" "}
              Lägg till{" "}
            </Button>
          </Paper>
        </Modal>
        {data ? (
          <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <div
                style={{
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  zIndex: 100,
                }}
              >
                <Button
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    backgroundColor: "white",
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.saveToBackend()}
                >
                  {" "}
                  Spara ändringar{" "}
                </Button>
              </div>
              <img src={logoUrl} className="App__logo" alt="logo" />
              <Paper className={classes.paper} style={{ width: 1000 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      style={{
                        cursor: "pointer",
                        marginRight: 10,
                        backgroundColor: "white",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => this.setState({ showBackdrop: true })}
                    >
                      {" "}
                      Lägg till ny kommmun{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      component="h1"
                      variant="h4"
                      align="center"
                      style={{ paddingTop: 20 }}
                    >
                      Admin portal
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl>
                      <InputLabel htmlFor="age-native-simple">
                        Välj kommun
                      </InputLabel>
                      <Select
                        native
                        style={{ width: 200 }}
                        value={this.state.selectKey}
                        onChange={(event) => this.selectMunicipaly(event)}
                        inputProps={{
                          name: "age",
                          id: "age-native-simple",
                        }}
                      >
                        <option value="" />
                        {municipalies.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow:
                        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        minWidth: 310,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {this.renderCheckBoxes(timepoints)}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          minHeight: 70,
                          alignItems: "center",
                        }}
                      >
                        {this.state.editTime ? (
                          <EditTime
                            close={() => this.showEditTime()}
                            save={(text) => this.saveTimepoint(text)}
                          />
                        ) : (
                          <div>
                            <Button
                              style={{ cursor: "pointer", marginRight: 10 }}
                              variant="outlined"
                              color="primary"
                              onClick={() => this.showEditTime()}
                            >
                              {" "}
                              Lägg till{" "}
                            </Button>
                            <Button
                              style={{ cursor: "pointer" }}
                              disabled={this.state.activeSteps.length === 0}
                              variant="outlined"
                              color="secondary"
                              onClick={() => this.removeSelected()}
                            >
                              {" "}
                              Ta bort markerade{" "}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <React.Fragment>
                    <FormPage
                      dontShowLevels={false}
                      title={"Personlig vård"}
                      values={timepointTasks}
                      ordinary
                      canEdit
                    />
                    <FormPage
                      dontShowLevels={false}
                      title={"Övriga insatser"}
                      values={extraTasks}
                      canEdit
                      extraTasks
                    />
                  </React.Fragment>
                </React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    {settings.formSettings.showSettings && (
                      <div style={{ marginTop: 30 }}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <TextField
                                value={this.state.doubleCareHours}
                                onChange={(e) =>
                                  this.setState({
                                    doubleCareHours: e.target.value,
                                  })
                                }
                                id="vaxel"
                                placeholder="0"
                              />
                            }
                            label={"veckor växelvård"}
                            labelPlacement="end"
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 20,
                            }}
                          >
                            <FormControlLabel
                              value="start"
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={() => {
                                    this.setState({
                                      answerModel: {
                                        ...this.state.answerModel,
                                        delad: !this.state.answerModel.delad,
                                      },
                                    });
                                  }}
                                />
                              }
                              label={"Dela med make/maka"}
                              labelPlacement="end"
                            />

                            <Tooltip
                              title={
                                <React.Fragment>
                                  <Typography variant="caption">
                                    {" "}
                                    Bostadens skösel städ, tvätt, inköp och
                                    tillredning av måltider delas med make/maka?
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <Question />
                            </Tooltip>
                          </div>
                        </FormControl>
                      </div>
                    )}
                    <CheckboxPage canEdit checkboxes={checkboxes} />
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Grid>

                <React.Fragment>
                  <div style={{ marginTop: 20 }}>
                    <TextField
                      variant="outlined"
                      style={{ marginRight: 20 }}
                      id={"alpha"}
                      label={"alpha weight"}
                      value={data.weights.alpha}
                      onChange={(event) => this.changeWeight("alpha", event)}
                    />
                    <TextField
                      variant="outlined"
                      style={{ marginRight: 20 }}
                      id={"beta"}
                      label={"beta weight"}
                      value={data.weights.beta}
                      onChange={(event) => this.changeWeight("beta", event)}
                    />
                  </div>
                </React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    <React.Fragment>
                      <FormControl
                        style={{
                          display: "flex",
                          paddingLeft: 40,
                          maginTop: 10,
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{ paddingTop: 20 }}
                        >
                          Inställningar
                        </Typography>
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              checked={settings.formSettings.showDescription}
                              color="primary"
                              onChange={(event) =>
                                this.changeSettings(
                                  "formSettings",
                                  "showDescription",
                                  event.target.checked
                                )
                              }
                            />
                          }
                          label={"Visa livsområden"}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              checked={settings.formSettings.showSettings}
                              color="primary"
                              onChange={(event) =>
                                this.changeSettings(
                                  "formSettings",
                                  "showSettings",
                                  event.target.checked
                                )
                              }
                            />
                          }
                          label={"Får dela vård med make/maka"}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              checked={
                                settings.calculationOutputSettings
                                  .showTimepoints
                              }
                              color="primary"
                              onChange={(event) =>
                                this.changeSettings(
                                  "calculationOutputSettings",
                                  "showTimepoints",
                                  event.target.checked
                                )
                              }
                            />
                          }
                          label={"Visa tidpunkter i beräkning"}
                          labelPlacement="end"
                        />
                        {this.state.editUrl ? (
                          <EditLogoUrl
                            logoUrl={logoUrl}
                            closeEdit={() => this.editUrl()}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: 20,
                            }}
                          >
                            <TextField
                              disabled={true}
                              id="Logo url"
                              label="Logo url"
                              type="text"
                              variant="outlined"
                              value={logoUrl}
                              style={{ width: 400 }}
                              onChange={(event) => console.log()}
                            />
                            <Button
                              style={{ marginLeft: 10, height: 56 }}
                              variant="outlined"
                              color="primary"
                              onClick={() => this.editUrl()}
                            >
                              Ändra
                            </Button>
                          </div>
                        )}
                      </FormControl>
                    </React.Fragment>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Grid>
                <React.Fragment>
                  <div style={{ marginTop: 20, paddingBottom: 20 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.saveStateToFile()}
                    >
                      Spara data till utvecklare
                    </Button>
                  </div>
                </React.Fragment>
              </Paper>
            </main>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <Paper
                className={classes.paper}
                style={{
                  minWidth: 1000,
                  minHeight: 300,
                  marginTop: 180,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  style={{ paddingBottom: 20 }}
                >
                  Admin portal
                </Typography>
                <FormControl>
                  <InputLabel htmlFor="age-native-simple">
                    Välj kommun
                  </InputLabel>
                  <Select
                    native
                    style={{ width: 200 }}
                    value={this.state.selectKey}
                    onChange={(event) => this.selectMunicipaly(event)}
                    inputProps={{
                      name: "age",
                      id: "age-native-simple",
                    }}
                  >
                    <option value="" />
                    {municipalies.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </FormControl>
                <Button
                  style={{
                    cursor: "pointer",
                    marginTop: 40,
                    backgroundColor: "white",
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.setState({ showBackdrop: true })}
                >
                  {" "}
                  Lägg till ny kommmun{" "}
                </Button>
              </Paper>
            </main>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) => ({
  backendDataReducer,
  answerModelReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addAllData: (data) => dispatch(addAllData(data)),
  addInput: (key, input) => dispatch(addInput(key, input)),
  changeTimepoints: (data) => dispatch(changeTimepoints(data)),
  changeSelectedMunicipaly: (name) => dispatch(changeSelectedMunicipaly(name)),
  changeWeights: (name, value) => dispatch(changeWeights(name, value)),
  changeSettings: (key, settingName, value) =>
    dispatch(changeSettings(key, settingName, value)),
  changeLogoUrl: (value) => dispatch(changeLogoUrl(value)),
  clearAllData: () => dispatch(clearAllData()),
});



const classes = (theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(Admin));