
export function validatePnr(pnr){
    return true
}

export function normalizePnr(pnr){
    let normilizedPnr = pnr;
    normilizedPnr = normilizedPnr.replace("-", "");
    normilizedPnr = normilizedPnr.replace(" ", "");
    if(pnr.length > 10){
        normilizedPnr = normilizedPnr.substring(2);
    }
    
    return normilizedPnr;

}
