export const UPDATE_WEEKS_OFF = 'UPDATE_WEEKS_OFF';
export const UPDATE_SHARED = 'UPDATE_SHARED';
export const SET_ALL_TIMEPOINTTASKS = 'SET_ALL_TIMEPOINTTASKS';
export const SET_TIMEPOINTTASKS = 'SET_TIMEPOINTTASKS';
export const UPDATE_TIMEPOINTTASK = 'UPDATE_TIMEPOINTTASK';
export const SET_EXTRATASKS = 'SET_EXTRATASKS';
export const UPDATE_EXTRATASK = 'UPDATE_EXTRATASK';
export const SET_CHECKBOXES = 'SET_CHECKBOXES';
export const UPDATE_CHECKBOXES = 'UPDATE_CHECKBOXES';


export const updateWeeksOff = (number) => ({
	type: UPDATE_WEEKS_OFF,
	payload: number
});

export const updateShared = (status) => ({
	type: UPDATE_SHARED,
	payload: status
});

export const setAllTimepointTasks = (timePointTasks) => ({
	type: SET_ALL_TIMEPOINTTASKS,
	payload: timePointTasks
});

export const setTimepointTasks = (time, timePointTasks) => ({
	type: SET_TIMEPOINTTASKS,
	payload: {
		time: time,
		timePointTasks: timePointTasks,
	}
});

export const updateTimepointTask = (time, taskName, taskData) => ({
	type: UPDATE_TIMEPOINTTASK,
	payload: {
		time: time,
		taskName: taskName,
		taskData: taskData,
	}
});

export const setExtraTask = (taskData) => ({
	type: SET_EXTRATASKS,
	payload: taskData
});

export const updateExtraTask = (taskName, taskData) => ({
	type: UPDATE_EXTRATASK,
	payload: {
		taskName: taskName,
		taskData: taskData,
	}
});

export const setCheckboxes = (checkboxes) => ({
	type: SET_CHECKBOXES,
	payload: checkboxes
});

export const updateCheckboxes = (key, checked) => ({
	type: UPDATE_CHECKBOXES,
	payload: {
		key: key,
		checked: checked,
	}
});
