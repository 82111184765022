import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { ReactComponent as Question } from '../resources/img/question.svg';
import { connect } from 'react-redux'
import { updateTimepointTask, updateExtraTask } from '../../actions/answerModelAction';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};




class InputFieldClient extends React.Component {

    saveDouble(value) {
        const { title, timepoint, answerModel, extraTasks } = this.props;
        const { selection, frequency } = answerModel;
        const obj = {
            frequency: frequency,
            selection: selection,
            double: value
        }

        if (!this.props.values.hasFrequency) {
            obj.frequency = "Var fjärde vecka"
        }


        if (extraTasks) {
            this.props.updateExtraTask(title, obj);
        } else {
            this.props.updateTimepointTask(timepoint, title, obj);
        }
    }

    savePerWeek(value) {
        const { title, timepoint, answerModel, extraTasks } = this.props;
        const { selection, double } = answerModel;
        const obj = {
            frequency: value,
            selection: selection,
            double: double
        }

        if (!this.props.values.hasFrequency) {
            obj.frequency = "Var fjärde vecka"
        }

        if (extraTasks) {
            this.props.updateExtraTask(title, obj);
        } else {
            this.props.updateTimepointTask(timepoint, title, obj);
        }
    }

    saveSelection(value) {
        const { title, timepoint, answerModel, extraTasks } = this.props;
        const { frequency, double } = answerModel;
        const obj = {
            frequency: frequency,
            selection: value,
            double: double
        }

        if (!this.props.values.hasFrequency) {
            obj.frequency = "Var fjärde vecka"
        }

        if (extraTasks) {
            this.props.updateExtraTask(title, obj);
        } else {
            this.props.updateTimepointTask(timepoint, title, obj);
        }

    }

    getMenuItems(value, frequency) {


        return (
            <Select
                placeholder=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={frequency}
                onChange={(event) => this.savePerWeek(event.target.value)}
            >
                <MenuItem value={"-"}>-</MenuItem>
                {
                    value.sevenTimesAWeek && (
                        <MenuItem value={"7 gånger i veckan"}>7 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.sixTimesAWeek && (
                        <MenuItem value={"6 gånger i veckan"}>6 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.fiveTimesAWeek && (
                        <MenuItem value={"5 gånger i veckan"}>5 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.fourTimesAWeek && (
                        <MenuItem value={"4 gånger i veckan"}>4 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.threeTimesAWeek && (
                        <MenuItem value={"3 gånger i veckan"}>3 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.twoTimesAWeek && (
                        <MenuItem value={"2 gånger i veckan"}>2 gånger i veckan</MenuItem>
                    )
                }
                {
                    value.oneTimesAWeek && (
                        <MenuItem value={"1 gång i veckan"}>1 gång i veckan</MenuItem>
                    )
                }
                {
                    value.secondWeek && (
                        <MenuItem value={"Varannan vecka"}>Varannan vecka</MenuItem>
                    )
                }
                {
                    value.thirdWeek && (
                        <MenuItem value={"Var tredje vecka"}>Var tredje vecka</MenuItem>
                    )
                }
                {
                    value.fourthWeek && (
                        <MenuItem value={"Var fjärde vecka"}>Var fjärde vecka</MenuItem>
                    )
                }



            </Select >
        )

    }

    render() {
        const { title, values, answerModel, extraTasks } = this.props;
        const { frequency, selection, double } = answerModel;
        const { selectedMunicipaly } = this.props.backendDataReducer;
        const { showDescription } = this.props.backendDataReducer.settings[selectedMunicipaly].formSettings;
        console.log("freq", frequency, double)
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" color="inherit" noWrap display="block" style={{ marginTop: 16 }}>
                        {title}
                    </Typography>
                    {showDescription &&
                        <Typography variant="caption" color="inherit" noWrap display="block" style={{ marginTop: 0 }}>
                            {values.description}
                        </Typography>
                    }

                </Grid>
                {values.hasFrequency ?
                    <Grid item xs={12} sm={3}>
                        {extraTasks ?
                            <FormControl>
                                {
                                    this.getMenuItems(values, frequency)
                                }
                                <FormHelperText>{"Frekvens"}</FormHelperText>
                            </FormControl>

                            :
                            <FormControl>
                                <Select
                                    placeholder=""
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={frequency}
                                    onChange={(event) => this.savePerWeek(event.target.value)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={0.25}>0.25</MenuItem>
                                    <MenuItem value={0.33}>0.33</MenuItem>
                                    <MenuItem value={0.5}>0.5</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                </Select>
                                <FormHelperText>{"Besök per vecka"}</FormHelperText>
                            </FormControl>
                        }
                    </Grid>
                    : <Grid item xs={12} sm={3} />
                }
                <Grid item xs={12} sm={3}>
                    {
                        (<FormControl style={{ marginRight: 10 }}>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selection}
                                onChange={(event) => this.saveSelection(event.target.value)}
                            >
                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <MenuItem value={level}>{level}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                            <FormHelperText>Välj nivå</FormHelperText>
                        </FormControl>)
                    }
                    <Tooltip
                        title={
                            <React.Fragment>

                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <div>
                                                <Typography variant="subtitle1">{level}</Typography>
                                                <Typography variant="caption">{this.props.levels[level]}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </React.Fragment>
                        }
                    >
                        <Question />
                    </Tooltip>



                </Grid>
                <Grid item xs={12} sm={3}>
                    {
                        values.double ? (
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        checked={!!double}
                                        color="primary"
                                        onChange={(event) => {
                                            this.saveDouble(event.target.checked)
                                        }}
                                    />
                                }
                                label={"Dubbelbemanning"}
                                labelPlacement="end"
                            />
                        ) : null
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) => ({
    backendDataReducer,
    answerModelReducer
});

const mapDispatchToProps = (dispatch) => ({
    updateTimepointTask: (time, taskName, taskData) => dispatch(updateTimepointTask(time, taskName, taskData)),
    updateExtraTask: (taskName, taskData) => dispatch(updateExtraTask(taskName, taskData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputFieldClient)