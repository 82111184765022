import React from 'react';
import "./App.css";
import Admin from "./components/pages/Admin";
import Client from "./components/pages/Client";
import Search from "./components/pages/Search";
import Error from "./components/pages/Error";
import UserManagment from './components/pages/Users/UserManagment';
import {
  Route,
  Routes,
  useSearchParams
} from "react-router-dom";
import Loading from "./components/partials/Loading";
import Footer from "./components/partials/Footer";
import EditUser from './components/pages/Users/EditUser';
import {useEid, SignInView} from './authentication'

const signInPage = process.env.REACT_APP_SIGN_IN_PAGE === "true"


function App() {
  const [searchParams] = useSearchParams();
  const [personalNumber, setPersonNumber] = React.useState();
  console.log("show sign in", signInPage)
  const { isAdmin, loading, isAuthenticated, loginWithRedirect } = useEid();

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
     if(signInPage){
      return <SignInView setPersonNumber={setPersonNumber} personalNumber={personalNumber} loginWithRedirect={loginWithRedirect} />
     }   
     
     loginWithRedirect()
  }

  return (
    <>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route
              path="/admin"
              element={<Admin token={sessionStorage.getItem("eidtoken")} />}
            />
            <Route
              path="/users/new"
              element={<EditUser isAdmin={isAdmin} token={sessionStorage.getItem("eidtoken")} />}
            />
            <Route
              path="/users"
              element={<UserManagment isAdmin={isAdmin} token={sessionStorage.getItem("eidtoken")} />}
            />
            
            <Route
              path="/"
              element={
                <div className="container">
                  <Client isAdmin={isAdmin} id={searchParams.get("id")}  token={sessionStorage.getItem("eidtoken")} />
                </div>
              }
            />
            <Route
              path="/search"
              element={
                <div className="search-container">
                  <Search isAdmin={isAdmin} token={sessionStorage.getItem("eidtoken")} />{" "}
                </div>
              }
            />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer></Footer>
        </header>
      </div>
    </>
  );
}

export default App;
