import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => (
  <div>
    <h1 style={{color: "black"}}>404 - Not Found!</h1>
    <Link to="/">Gå tillbaka till start</Link>
  </div>
);

export default Error;