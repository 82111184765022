import React from "react";
import * as BsIcons from "react-icons/bs";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const FooterContainer = styled.div`
  color: white;
  background-color: #2196f3;
  padding-top: 2rem;
  margin-top: 2em;
  bottom: 10;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column1 = styled.div`
  width: 20%;
`;

const Column2 = styled.div`
  width: 20%;
`;

const Column3 = styled.div`
  width: 20%;
`;

const Contact = styled.div`
  margin-top: 2rem;
`;

const SocialIcons = styled.div`
  text-decoration: none;
  decoration: none;
  color: #ffffff;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Icon = styled.div`
  margin-right: 5%;
`;

const BackgroundText = styled.div`
  margin-top: 2rem;
`;

const LinkA = styled.a`
  margin-top: 2rem;
  color: white;
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <Column1>
          <Typography variant="h6">
            Följ, Dela och Gilla oss på sociala medier
          </Typography>
          <SocialIcons>
            <Icon>
              <LinkA href="https://www.inrikta.se" target="_blank">
                <BsIcons.BsFacebook />
              </LinkA>
            </Icon>

            <Icon>
              <LinkA href="https://www.inrikta.se" target="_blank">
                <BsIcons.BsLinkedin />
              </LinkA>
            </Icon>

            <Icon>
              <LinkA href="https://www.inrikta.se" target="_blank">
                <BsIcons.BsTwitter />
              </LinkA>
            </Icon>

            <Icon>
              <LinkA href="https://www.inrikta.se" target="_blank">
                <BsIcons.BsInstagram />
              </LinkA>
            </Icon>
          </SocialIcons>
        </Column1>

        <Column2>
          <Typography variant="h6">Kontakta oss</Typography>
          <Contact>
            <Typography>Telefon: 123 456 789</Typography>
            <Typography>Skeppsbron 8</Typography>
            <Typography>111 30 Stockholm</Typography>
          </Contact>
        </Column2>

        <Column3>
          <Typography variant="h6">Bakgrund</Typography>
          <BackgroundText>
            <Typography>
              inRikta är en akademisk avknoppning inom området Service
              Operations Management med verksamhet i Sverige sedan 2008.
              Tidigare hette företaget Nordic Healthcare Group, NHG Sweden AB
            </Typography>
          </BackgroundText>
        </Column3>
      </FooterContainer>
    </>
  );
};

export default Footer;
