import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import * as IoIcons from 'react-icons/io';
import { Link } from 'react-router-dom'

const StyledButton = styled.div`
  color: white;
  margin-right: -15px;
  z-index: 2;
  &:hover{
    cursor: pointer;
  }
`;
const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: 'white'
};

const CalculateButton = () => {
    return (
    <Tooltip title="Beräkna">
      <StyledButton> <Link to = "/" style={linkStyle} > <IoIcons.IoMdCalculator size={40}/> </Link></StyledButton>
    </Tooltip>  
        
  )
}

export default CalculateButton
