import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const showOnlyTotalTime = process.env.REACT_APP_SHOW_ONLY_TOTAL_TIME === "true"

export class InfoCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
        }
    }

    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return  rhours + "h " + rminutes + " min";
        }

    archive = () => {
        this.props.archive(this.props.data.id)
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded })
    };

    getHours = (num) => {
        return Math.floor(num);
    }

    getMinutes = (num) => {
        return Math.floor((num - this.getHours(num)) * 60);
    }

    createTimeString = (hours) => {
        return "" + this.getHours(hours) + "h " + this.getMinutes(hours) + "min"
    }

    render() {

        return (
            <div style={
                {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '60%',
                    paddingBottom: '30px',
                    textAlign: 'left',
                    cursor: 'pointer',
                }
            }>
                <Card elevation={3} onClick={this.handleExpandClick}>
                    <CardHeader
                        title={`Process ID: ${this.props.data.PID} ${this.props.data.draft ? "UTKAST" : ""}`}
                        subheader={`Skapades: ${this.props.data.created.substring(0, 10)} av ${this.props.data.data.municipality}`}

                    />
                    <CardContent>
                        {
                            showOnlyTotalTime ? (
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Total tid: {this.timeConvert(this.props.data.data.calculatedTime.totalExDouble * 60)} 
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Total tid per månad: {this.createTimeString(this.props.data.data.calculatedTime.totalHoursPerMonth)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tid för personlig vård per månad: {this.createTimeString(this.props.data.data.calculatedTime.hoursPerMonthTimepointTasks)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tid för dubbelbemmaning per månad: {this.createTimeString(this.props.data.data.calculatedTime.hoursPerMonthTimePointsTasksDouble)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tid för övriga insatser per månad: {this.createTimeString(this.props.data.data.calculatedTime.hoursPerMonthExtraTasks)}
                                    </Typography>
                                    {
                                        Object.keys(this.props.data.data.state.checkboxes).map((key, i) => (
                                            <Typography variant="body2" color="textSecondary" component="p" key={i}>
                                                {key}: {this.props.data.data.state.checkboxes[key] ? "Ja" : "Nej"}
                                            </Typography>
                                    ))}
                                </>
                            )
                        }
                        
                        <div style={{ marginTop: 10 }}>
                            <Button variant="outlined" color="primary" style={{ marginRight: 5 }}><a href={"/?id=" + this.props.data.id}>Kopiera tidigare underlag</a></Button>
                            <Button onClick={() => { this.archive() }} variant="outlined" color="primary">Makulera</Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        )
    }
}
