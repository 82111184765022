import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom'

const StyledButton = styled.div`
  color: white;
  align-text: center;
  z-index: 2;
  &:hover{
    cursor: pointer;
  }
`;

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: 'white'
};

const UsersButton = () => {
  return (
    <Tooltip title="Hantera användare">
        <StyledButton>
            <Link to="/users" style={linkStyle}>
                <MdIcons.MdManageAccounts size={40} />
            </Link>
        </StyledButton>
    </Tooltip>
  )
}

export default UsersButton
